<template>
  <div>
    <!-- menu -->
    <transition name="menu">
      <div
        v-if="showMenu"
        class="d-flex align-items-start align-items-lg-center justify-content-center position-fixed w-100 h-100 top-0 end-0 bg-dark"
        :class="$style.zIndex30"
      >
        <!-- cross -->
        <div
          role="button"
          class="position-fixed top-0 end-0 p-1 pt-2 m-4 d-flex d-lg-none flex-column align-items-center justify-content-center bg-dark rounded-circle"
          @click="setShowMenu(false)"
        >
          <AppIcon
            name="cross"
            class="icon"
          />
        </div>
        <!-- menu item -->
        <ul
          class="list-unstyled d-flex flex-column"
          :class="$style.menuList"
        >
          <a
            v-for="(item, index) in navList"
            :key="index"
            role="button"
            class="pb-3 mb-1 text-white"
            :class="$style.menuItem"
            @click="scrollToPosition(item.hash)"
          >
            <div class="h3">
              {{ item.title }}
            </div>
            <div class="small">
              {{ item.subTitle.split(' ')[0].toUpperCase() }}
              <span class="text-primary">{{ item.subTitle.split(' ')[1].toUpperCase() }}</span>
            </div>
          </a>
        </ul>
      </div>
    </transition>
    <!-- 手機板選單 -->
    <div
      role="button"
      class="position-fixed top-0 end-0 m-3 d-flex d-lg-none flex-column align-items-center justify-content-center rounded-circle"
      :class="$style.hamburgerMobile"
      @click="setShowMenu()"
    >
      <div class="d-flex flex-column align-items-start">
        <AppIcon
          name="hamburger"
          class="icon-sm"
        />
      </div>
    </div>
    <!-- 電腦版選單 -->
    <nav
      class="position-fixed top-0 start-0 min-vh-100 d-none d-lg-flex flex-column align-items-center justify-content-start bg-dark"
      :class="$style.nav"
    >
      <div class="d-flex flex-column align-items-start">
        <!-- logo -->
        <div class="pt-5 pb-4">
          <router-link
            to="/"
            class="d-flex flex-column align-items-start text-primary"
            :class="$style.logo"
          >
            <img
              :src="headerlogo?.src"
              :alt="headerlogo?.alt"
              class="w-100"
            >
          </router-link>
        </div>
        <!-- menu item -->
        <ul
          class="list-unstyled d-flex flex-column"
          :class="$style.menuList"
        >
          <a
            v-for="(item, index) in navList"
            :key="index"
            role="button"
            class="pb-3 mb-1 text-white"
            :class="$style.menuItem"
            @click="scrollToPosition(item.hash)"
          >
            <div
              class="h3"
              :class="$style.title"
            >
              {{ item.title }}
            </div>
            <div class="small">
              {{ item.subTitle.split(' ')[0].toUpperCase() }}
              <span class="text-primary">{{ item.subTitle.split(' ')[1].toUpperCase() }}</span>
            </div>
          </a>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { computed } from 'vue-demi'
import { useRouter } from 'vue-router'
export default {
  name: 'TheNav',
  async setup () {
    const { getters } = useStore()
    const headerlogo = computed(() => getters.getIndex.headerlogo)

    const router = useRouter()

    router.beforeEach((to, from, next) => {
      setShowMenu(false)
      next()
    })

    const navList = ref([
      {
        title: '關於我們',
        subTitle: 'ABOUT SHEEN.C',
        hash: 'about'
      },
      {
        title: '設計師系列',
        subTitle: 'special product',
        hash: 'product'
      },
      {
        title: '客製化流程',
        subTitle: 'all customized',
        hash: 'customized'
      },
      {
        title: '客製作品',
        subTitle: 'customized work',
        hash: 'work'
      },
      {
        title: '客戶見證',
        subTitle: 'client Testimonials',
        hash: 'client'
      },
      {
        title: '常見問題',
        subTitle: 'Customization FQA',
        hash: 'QA'
      },
      {
        title: '聯絡我們',
        subTitle: 'reservation connect',
        hash: 'reservation'
      }
    ])

    const showMenu = ref(false)
    const setShowMenu = (active) => {
      showMenu.value = typeof active === 'boolean' ? active : !showMenu.value
    }

    const scrollToPosition = (id) => {
      const section = document.querySelector(`#${id}`)
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth'
      })
      setShowMenu(false)
    }

    return { headerlogo, showMenu, setShowMenu, navList, scrollToPosition }
  }
}
</script>

<style lang="scss" module>
.logo {
  width: 120px;
  transition: all 0.3s;

  &:hover {
    opacity: 0.6;
  }
}

:global(.menu-enter-active),
:global(.menu-leave-active) {
  transition: transform 0.3s ease;
}

:global(.menu-enter-from),
:global(.menu-leave-to) {
  transform: translate(0%, -100%);
}

.menuList {
  margin: 100px 0 0;

  @include media-breakpoint-up(lg) {
    margin: 0;
  }
}

.title {
  transition: all 0.3s;
}

.menuItem {
  &:hover .title {
    color: $primary;
  }
}

.zIndex30 {
  z-index: 30;
}

.hamburgerMobile {
  z-index: 20;
  width: 3.375rem;
  height: 3.375rem;
}

.nav {
  z-index: 40;
  width: $nav-width;
}

.menuButton {
  margin-bottom: 6rem;
  width: 40px;
  height: 56px;
}

.link {
  position: relative;
  color: $gray;
  transition: all 0.3s;

  &:hover {
    color: $primary;
  }
}

</style>
