<template>
  <footer>
    <div class="bg-black text-white px-lg-4 pb-lg-3 pt-lg-5">
      <div class="container-fluid gx-0 gx-lg-3">
        <div class="row gx-0 gx-lg-3">
          <div class="col-12 d-block d-lg-none">
            <div
              role="button"
              class="text-center py-4 mb-3 h4 text-white"
              @click="scrollToTop"
            >
              TOP
            </div>
          </div>
          <div class="col-12 col-lg-2 flex-grow-0 d-flex justify-content-center">
            <div class="d-flex flex-row flex-lg-column align-items-center mb-4">
              <router-link
                to="/"
                class="flex-grow-1 d-block mb-2 me-3 me-lg-0"
                :class="$style.link"
              >
                <img
                  :src="footer?.logo?.src"
                  :alt="footer?.logo?.alt"
                  class="mx-auto mb-lg-3"
                  :class="$style.logo"
                >
              </router-link>
              <div class="d-flex align-items-center">
                <a
                  v-for="(item, index) in footerSocial"
                  :key="index"
                  :href="item.href"
                  :class="$style.link"
                  target="_blank"
                >
                  <AppIcon
                    :name="`social-${item.name.toLowerCase()}`"
                    class="mx-2"
                    :class="$style.icon"
                  />
                </a>
              </div>
            </div>
          </div>
          <!-- mobile -->
          <div class="d-block d-lg-none col-12">
            <h4
              role="button"
              class="d-flex align-items-center justify-content-between p-3"
              @click="setShowContact"
            >
              營業處資訊
              <AppIcon
                v-if="showContact"
                name="minus"
                class="icon-sm"
              />
              <AppIcon
                v-else
                name="plus"
                class="icon-sm"
              />
            </h4>
            <div class="border-bottom" />
            <transition name="collapse">
              <div
                v-if="showContact"
                class="bg-body text-white"
              >
                <p
                  v-for="(item, index) in footer.location"
                  :key="index"
                  class="p-3"
                >
                  地址：{{ item.address }}<br>
                  電話：<a
                    :href="`tel:${item.phone}`"
                    class="text-white"
                  >{{ item.phone }}</a><br>
                  <!-- 傳真：{{ footer.serviceTime }}<br> -->
                  Email：<a
                    :href="`mailto:${item.email}`"
                    class="text-white"
                  >{{ item.email }}</a><br>
                  服務時間：{{ item.servicetime }}
                </p>
              </div>
            </transition>
          </div>
          <!-- computer -->
          <div class="d-none d-lg-flex justify-content-start flex-wrap col-lg flex-grow-2">
            <div
              v-for="(item, index) in footer.location"
              :key="index"
              class="col"
            >
              <div class="d-flex align-items-center mb-2">
                <h4>
                  地址：
                </h4>
                <p>{{ item.address }}</p>
              </div>
              <div class="d-flex align-items-center mb-2">
                <h4>
                  電話：
                </h4>
                <a
                  :href="`tel:${item.phone}`"
                  class="text-white"
                >{{ item.phone }}</a>
              </div>
              <!-- <div>
              <h4>
                傳真：
              </h4>
              <p>{{ footer.serviceTime }}</p>
            </div> -->
              <div class="d-flex align-items-center mb-2">
                <h4>
                  Email：
                </h4>
                <a
                  :href="`mailto:${item.email}`"
                  class="text-white"
                >{{ item.email }}</a>
              </div>
              <div class="d-flex align-items-center">
                <h4>
                  服務時間：
                </h4>
                <p>{{ item.servicetime }}</p>
              </div>
            </div>
          </div>
          <!-- mobile -->
          <div class="d-block d-lg-none col-12">
            <h4
              role="button"
              class="d-flex align-items-center justify-content-between p-3"
              @click="setShowInfo"
            >
              網站資訊
              <AppIcon
                v-if="showInfo"
                name="minus"
                class="icon-sm"
              />
              <AppIcon
                v-else
                name="plus"
                class="icon-sm"
              />
            </h4>
            <div class="border-bottom" />
            <transition name="collapse">
              <div v-if="showInfo">
                <div class="d-flex flex-column bg-body text-white p-3">
                  <a
                    v-for="(item, index) in footer.customPage.page"
                    :key="index"
                    :href="item.href"
                    :class="$style.link"
                  >{{ item.name }}</a>
                </div>
              </div>
            </transition>
          </div>
          <!-- computer -->
          <div class="col-12 d-none d-lg-flex justify-content-end pt-5 px-5">
            <a
              v-for="(item, index) in footer.customPage.page"
              :key="index"
              :href="item.href"
              class="d-block me-3"
              :class="$style.link"
            >{{ item.name }}</a>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex align-items-center justify-content-between px-3 pt-3 pb-4"
      :class="$style.copyRightMargin"
    >
      <small>{{ footer.copyright }}</small>
      <small>Design by
        <a
          href="https://wwooz.com"
          class="text-white"
          target="_blank"
        >沃知</a></small>
    </div>
  </footer>
</template>

<script>
import { ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { computed } from 'vue-demi'
export default {
  name: 'TheFooter',
  async setup () {
    const { getters } = useStore()

    const footer = computed(() => getters.getIndex.footer)

    const footerSocial = computed(() => {
      if (footer.value.social && footer.value.social.length > 0) {
        return footer.value.social.filter((item) => item.href && item.href !== 'https://')
      } else {
        return []
      }
    })

    const showContact = ref(false)
    const setShowContact = () => {
      showContact.value = !showContact.value
    }

    const showInfo = ref(false)
    const setShowInfo = () => {
      showInfo.value = !showInfo.value
    }
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }

    return {
      scrollToTop,
      footerSocial,
      footer,
      showContact,
      setShowContact,
      showInfo,
      setShowInfo
    }
  }
}
</script>

<style lang="scss" module>
.icon {
  width: 40px;
  height: 40px;
  fill: currentColor;

  @include media-breakpoint-up(lg) {
    width: 24px;
    height: 24px;
  }
}

.logo {
  width: 60px;
  transition: all 0.3s;

  @include media-breakpoint-up(lg) {
    width: 80px;
  }

  &:hover {
    opacity: 0.6;
  }
}

.link {
  color: $white;
  transition: all 0.3s;

  &:hover {
    color: $primary;
  }
}

@include media-breakpoint-up(lg) {
  .link {
    color: $white;
    transition: all 0.3s;

    &:hover {
      fill: $primary;
      color: $primary;
    }
  }
}

.copyRightMargin {
  @include media-breakpoint-up(lg) {
    margin-right: 80px;
  }
}
</style>
