import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { useAPI } from '@/helpers/useApi'

import Cookies from 'js-cookie'

export default createStore({
  state: {
    index: {},
    cookiePolicy: {},
    privacyPolicy: {},
    disclaimer: {},
    photobookDetail: {}
  },
  getters: {
    getIndex: state => state.index,
    getCookiePolicy: state => state.cookiePolicy,
    getPrivacyPolicy: state => state.privacyPolicy,
    getDisclaimer: state => state.disclaimer,
    getPhotobookDetail: state => state.photobookDetail
  },
  mutations: {
    setIndex: (state, { data }) => { state.index = data },
    setCookiePolicy: (state, { data }) => { state.cookiePolicy = data },
    setDisclaimer: (state, { data }) => { state.disclaimer = data },
    setPrivacyPolicy: (state, { data }) => { state.privacyPolicy = data },
    setPhotobookDetail: (state, { data }) => { state.photobookDetail = data }
  },
  actions: {
    readCookiePolicy: async ({ commit }, { payload }) => {
      const method = 'get'
      const path = '/api_cookiePolicy.php'
      const data = { ...payload }

      try {
        const response = await useAPI({ method, path, data })
        const responseData = response.data
        console.log(responseData)
        commit('setCookiePolicy', { data: responseData })
        return response
      } catch (error) {
        commit('setCookiePolicy', { data: {} })
        return Promise.reject(error)
      }
    },
    readPrivacyPolicy: async ({ commit }, { payload }) => {
      const method = 'get'
      const path = '/api_privacyPolicy.php'
      const data = { ...payload }

      try {
        const response = await useAPI({ method, path, data })
        const responseData = response.data
        console.log(responseData)
        commit('setPrivacyPolicy', { data: responseData })
        return response
      } catch (error) {
        commit('setPrivacyPolicy', { data: {} })
        return Promise.reject(error)
      }
    },
    readDisclaimer: async ({ commit }, { payload }) => {
      const method = 'get'
      const path = '/api_disclaimer.php'
      const data = { ...payload }

      try {
        const response = await useAPI({ method, path, data })
        const responseData = response.data
        console.log(responseData)
        commit('setDisclaimer', { data: responseData })
        return response
      } catch (error) {
        commit('setDisclaimer', { data: {} })
        return Promise.reject(error)
      }
    },
    readIndex: async ({ commit }, { payload }) => {
      const method = 'get'
      const path = '/api_get.php'
      const data = { ...payload }

      try {
        const response = await useAPI({ method, path, data })
        const responseData = response.data
        console.log(responseData)
        commit('setIndex', { data: responseData })
        return response
      } catch (error) {
        commit('setIndex', { data: {} })
        return Promise.reject(error)
      }
    },
    readPhotobookDetail: async ({ commit }, { payload }) => {
      const method = 'get'
      const path = '/api_photobookdetail.php'
      const data = { ...payload }

      try {
        const response = await useAPI({ method, path, data })
        const responseData = response.data.photoBook
        console.log(responseData)
        commit('setPhotobookDetail', { data: responseData })
        return response
      } catch (error) {
        commit('setPhotobookDetail', { data: {} })
        return Promise.reject(error)
      }
    },
    createReservation: async ({ commit }, { payload }) => {
      const method = 'post'
      const path = '/api_reservation.php'
      const data = { ...payload }

      try {
        const response = await useAPI({ method, path, data })
        const responseData = response.data
        console.log(responseData)
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  plugins: [
    createPersistedState({
      key: '_State_',
      storage: {
        getItem: (key) => {
          Cookies.get(key)
        },
        setItem: (key, value) => {
          Cookies.set(key, value, { expires: 7, secure: true })
        },
        removeItem: (key) => Cookies.remove(key)
      }
    })
  ]
})
